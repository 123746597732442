import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_avatar, {
        button: "",
        "router-link": `/profile/${$props.member.id}`,
        slot: "start"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", { src: $options.profileImage }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["router-link"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString($options.username), 1),
          ($props.member.comment)
            ? (_openBlock(), _createBlock(_component_ion_note, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.member.comment), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      ($props.respond)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 0,
            slot: "end",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openRespond()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($options.buttonText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}