
import {IonItem, IonAvatar, IonLabel, IonNote, IonButton} from '@ionic/vue';
import {personCircleOutline} from "ionicons/icons";
import profileHelper from "@/helpers/ImageHelper";


export default {
  name: 'MemberItem',
  emits: ['openRespond'],
  props: {
    member: Object,
    respond: {
      type: Boolean,
      default: false
    },
    displayStatus:{
      type: Boolean,
      default: false
    }
  },
  components: {
    IonItem,
    IonAvatar,
    IonLabel,
    IonNote,
    IonButton
  },
  setup() {
    return {
      personCircleOutline
    }
  },
  computed: {
    username() {
      return this.member.firstName + ' ' + (this.member.lastName != null ? this.member.lastName : '');
    },
    profileImage() {
      return profileHelper.profileImage(this.member)
    },
    buttonText(){
      return this.displayStatus? this.member.role : 'Respond';
    }

  },
  methods: {
    openRespond() {
      this.$emit("openRespond", this.member.id);
    },
  }

}

