

import { IonContent, IonHeader, IonToolbar, IonList, IonItem, IonLabel, IonModal,IonLoading, IonTitle,} from '@ionic/vue';
import ActivityEditModal from "@/components/modal/ActivityEditModal.vue";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
  name:'ActivitySettingsModal',
  emits:['settingsClose'],
  components:{
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    ActivityEditModal,
    IonModal,
    IonLoading,
    IonTitle
  },
  setup(){
    return {
    }
  },
  methods: {
    cancel() {
      this.$emit("settingsClose");
    },
    openEditModal() {
      this.editModalIsOpen = true;
    },
    handleEditSave() {
      this.editModalIsOpen = false;
      this.cancel();
    },
    handleEditCancel() {
      this.editModalIsOpen = false;
    },
    takePhoto(){
      return this.$store.dispatch('activity/photo',this.activity);
    },
  },
  computed:{
    details(){
      const details = JSON.parse(JSON.stringify(this.activity));
      details.startTime = dayjs(this.activity.startTime).format('HH:mm');
      return details;
    },
    ...mapGetters({
      activity: 'activity/details',
      loading: 'activity/loading'
    }),
  },
  data() {
    return {
      success: false,
      error: false,
      editModalIsOpen: false,
      // loading : false
    }
  },
}
