import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_alert = _resolveComponent("ion-alert")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_alert, {
      "is-open": $setup.isOpenRef,
      header: "Are you sure?",
      "sub-header": "",
      message: "",
      "css-class": "my-custom-class",
      buttons: $setup.buttons,
      onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => ($setup.setOpen(false)))
    }, null, 8, ["is-open", "buttons"]),
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_ion_back_button, null, null, 512), [
                  [_vShow, $props.backLink]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                ($props.signup)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      disabled: $props.signup.disableButton,
                      onClick: $options.presentAlertConfirm
                    }, {
                      default: _withCtx(() => [
                        ($props.signup.going)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              color: "danger",
                              icon: $setup.removeCircle
                            }, null, 8, ["icon"]))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              icon: $setup.addCircle
                            }, null, 8, ["icon"]))
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                ($options.displaySettings)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 1,
                      onClick: $options.openSettings
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: $setup.settingsOutline }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_button, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_menu_button, { id: "main" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.tabTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ], 64))
}