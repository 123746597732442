
import {IonPage, IonContent, IonModal } from '@ionic/vue';
import theHeader from "@/components/theHeader.vue";
import ActivitySettingsModal from "@/components/modal/ActivitySettingsModal.vue";
import GroupSettingsModal from "@/components/modal/GroupSettingsModal.vue";

export default {
  name:'BaseLayout',
  props:{
    title:{
      type: String,
      default: 'Athlete Groups'
    },
    backLink:{
      type: Boolean,
      default: false
    },
    signup:{
      type: Object,
      default:null
    },
    settings:{
      type: Object,
      default: null
    }
  },
  components:{
    IonPage,
    IonContent,
    theHeader,
    IonModal,
    ActivitySettingsModal,
    GroupSettingsModal
  },
  computed:{
     activitySettings(){
       return this.settings != null && this.settings.type==='activity' && this.settings.canEdit;
     },
    groupSettings(){
      return this.settings != null && this.settings.type==='group' && this.settings.canEdit;
    }
  },
  methods:{
    openSettings() {
      this.settingsOpen = !this.settingsOpen;
    },
    closeSettings(){
      this.settingsOpen = false;
    },
  },
  data(){
    return{
      settingsOpen: false,
    }
  }
}
