import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_activity_settings_modal = _resolveComponent("activity-settings-modal")!
  const _component_Group_settings_modal = _resolveComponent("Group-settings-modal")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_the_header, {
        "tab-title": $props.title,
        "back-link": $props.backLink,
        signup: $props.signup,
        settings: $props.settings,
        onOpenSettings: $options.openSettings
      }, null, 8, ["tab-title", "back-link", "signup", "settings", "onOpenSettings"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_ion_modal, {
            "is-open": $data.settingsOpen,
            onDidDismiss: $options.closeSettings,
            "initial-breakpoint": "0.35"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_activity_settings_modal, { onSettingsClose: $options.openSettings }, null, 8, ["onSettingsClose"]), [
                [_vShow, $options.activitySettings]
              ]),
              _withDirectives(_createVNode(_component_Group_settings_modal, { onSettingsClose: $options.openSettings }, null, 8, ["onSettingsClose"]), [
                [_vShow, $options.groupSettings]
              ])
            ]),
            _: 1
          }, 8, ["is-open", "onDidDismiss"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}