export default {

    profileImage : function(profile){
        let img = 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y';
        if(typeof profile !== 'undefined' && profile.profileImage != ''
            && profile.profileImage != null){
            img = process.env.VUE_APP_IMAGE_URL+'profile/'+profile.profileImage;
        }
        return img;

    },
    backgroundImage : function(type, image){
        return 'linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.40))'+
            ((typeof image !== 'undefined'&& image && type)? ',url("'+process.env.VUE_APP_IMAGE_URL+type+'/'+image+'")':'');
    }
}