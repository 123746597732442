

import { IonContent, IonHeader, IonToolbar,  IonTitle, IonButton, IonButtons,
   IonIcon,useIonRouter} from '@ionic/vue';
import { useVuelidate } from "@vuelidate/core";
import { saveOutline,trashOutline, chevronBackOutline } from 'ionicons/icons';
import GroupForm from "@/components/form/groupForm.vue";
export default {
  name:'GroupEditModal',
  props:{
    group: Object,
  },
  emits:['modalSave','modalCancel'],
  components:{
    GroupForm,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon
  },
  setup(){
    const ionRouter = useIonRouter();
    return {
      v: useVuelidate(),
      saveOutline,
      trashOutline,
      chevronBackOutline,
      ionRouter
    }
  },
  methods: {
    save(group) {
      this.$store.dispatch('group/update',group).then(
          () =>{
            this.$emit("modalSave");
          }
      );
    },
    cancel() {
      this.$emit("modalCancel");
    },
  },
  computed:{
  },
  data() {
    return {
    }
  },

}

