
    import { IonHeader, IonToolbar, IonTitle, IonIcon,IonButtons,
      IonBackButton, IonButton, IonMenuButton, IonAlert, alertController} from '@ionic/vue';
    import { home, menu, arrowBackOutline, addCircle, removeCircle, settingsOutline } from 'ionicons/icons';
    import {ref} from 'vue';
    export default  {
        name: 'theHeader',
        emits: ['openSettings'],
        props:{
            'tabTitle': String,
             'homeButton':{
              type: Boolean,
               default: true
             },
            backLink:{
              type: Boolean,
              default: false
            },
          signup:{
              type:Object,
              default: null
          },
          settings: {
            type: Object,
            default: null
            // default(){
            //   return {type: null, canEdit: false}
            // }
          }
        },
        components: {
          IonHeader,
          IonToolbar,
          IonTitle,
          IonIcon,
          IonButton,
          IonBackButton,
          IonMenuButton,
          IonAlert,
          IonButtons
        },
      computed:{
          displaySettings(){
            if(this.settings){
              return this.settings.canEdit;
            }
            return false;
          }
      },
      methods:{
        openSettings(){
            this.$emit("openSettings");
        },
        join(){
          const signup =this.$props.signup;
          const join = {
            join: !signup.going,
            participationId: this.$route.params.id,
            athleteId: this.$store.getters.accountId
          }
          if(signup.action === 'activity'){
            this.$store.dispatch('activity/join', join).catch(() => {
                  this.presentAlert("Error joining activity");
                });
          }
          if(signup.action === 'event'){
            this.$store.dispatch('joinEvent', join);
          }
          //if action is event call event service
          if(signup.action === 'group'){
            this.$store.dispatch('group/joinGroup', join);
          }
        },
        async presentAlertConfirm() {
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: 'Confirm',
                message: (this.signup.going?'Leave':'Join')+' '+this.signup.action,
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                      console.log('Confirm Cancel:', blah)
                    },
                  },
                  {
                    text: 'Yes',
                    id: 'confirm-button',
                    handler: () => {
                      this.join();
                    },
                  },
                ],
              });
          return alert.present();
        },
        async presentAlert(error) {
          const alert = await alertController.create({
            header: 'Error',
            message: error,
            buttons: ['OK']
          });
          await alert.present();
        },
      },
        setup(){
          const isOpenRef = ref(false);
          const setOpen = (state: boolean) => isOpenRef.value = state;
          const buttons = ['yes', 'no'];

          return{
            menu,
            home,
            arrowBackOutline,
            addCircle,
            removeCircle,
            setOpen,
            isOpenRef,
            buttons,
            settingsOutline
          }
        }
    }
