

import { IonContent, IonHeader, IonToolbar, IonList, IonItem, IonLabel, IonModal,IonLoading, IonTitle,} from '@ionic/vue';
import {mapGetters} from "vuex";
import GroupEditModal from "@/components/modal/GroupEditModal.vue";
import MemberModal from "@/components/modal/MemberModal.vue";


export default {
  name:'GroupSettingsModal',
  emits:['settingsClose'],
  components:{
    MemberModal,
    GroupEditModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonList,
    IonLabel,
    // ActivityEditModal,
    IonModal,
    IonLoading,
    IonTitle
  },
  setup(){
    return {
    }
  },
  methods: {
    cancel() {
      this.$emit("settingsClose");
    },
    openEditModal() {
      this.editModalIsOpen = true;
    },
    handleEditSave() {
      this.editModalIsOpen = false;
      this.adminModalIsOpen = false;
      this.cancel();
    },
    handleEditCancel() {
      this.editModalIsOpen = false;
      this.adminModalIsOpen = false;
    },
    openAdminModal(){
      this.adminModalIsOpen = true;
    },
    takePhoto(){
      return this.$store.dispatch('group/photo',this.group);
    },
  },
  computed:{
    ...mapGetters({
      group: 'group/group',
      loading: 'activity/loading'
    }),
  },
  data() {
    return {
      success: false,
      error: false,
      editModalIsOpen: false,
      adminModalIsOpen: false,
      // loading : false
    }
  },
}
