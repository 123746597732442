

import { IonContent, IonHeader, IonToolbar, IonItem, IonInput, IonTextarea, IonTitle, IonButton, IonButtons,
  IonLabel, IonCheckbox, IonItemGroup, IonToggle, IonIcon, alertController,useIonRouter} from '@ionic/vue';
import { useVuelidate } from "@vuelidate/core";
import {  required} from "@vuelidate/validators";
import { saveOutline,trashOutline, chevronBackOutline } from 'ionicons/icons';
export default {
  name:'ActivityEditModal',
  props:{
    activity: Object,
  },
  emits:['modalSave','modalCancel'],
  components:{
    IonContent,
    IonHeader,
    IonToolbar,
    IonItem,
    IonInput,
    IonTextarea,
    IonToggle,
    IonCheckbox,
    IonItemGroup,
    IonTitle,
    IonButton,
    IonButtons,
    IonLabel,
    IonIcon
  },
  setup(){
    const ionRouter = useIonRouter();
    return {
      v: useVuelidate(),
      saveOutline,
      trashOutline,
      chevronBackOutline,
      ionRouter
    }
  },
  methods: {
    save() {
      this.$store.dispatch('activity/update',this.activity).then(
          () =>{
            this.$emit("modalSave");
          }
      );
    },
    cancel() {
      this.$emit("modalCancel");
    },
    delete(){
      console.log('activity deleted')
      this.$store.dispatch('activity/delete', this.activity).then(
          () => {
            this.ionRouter.canGoBack?
                this.ionRouter.replace('/home'):
                this.ionRouter.push('/home');
            this.ionRouter.push('/home');
            this.$emit("modalSave");
          }
      )
    },
    async presentAlertConfirm() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Delete Activity?',
            message: 'This will delete all current and future of this type of activity',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel')
                },
              },
              {
                text: 'Yes',
                role: 'confirm',
                handler: () => {
                  this.delete();
                },
              },
            ],
          });
      return alert.present();
    },
    repeatActivityToggle(){
      this.isRepeat = !this.isRepeat;
      if(!this.isRepeat){
        this.repeat.forEach( day => day.isChecked =false);
      }
    },
    checkRepeats(){
      return this.activity?
      (this.activity.monday || this.activity.tuesday || this.activity.wednesday ||
          this.activity.thursday ||
          this.activity.friday ||
          this.activity.saturday ||
          this.activity.sunday) : false;
    },
    fillRepeat(){
     return this.activity?[
        { val: 'Monday', isChecked: this.activity.monday },
        { val: 'Tuesday', isChecked: this.activity.tuesday },
        { val: 'Wednesday', isChecked: this.activity.wednesday },
        { val: 'Thursday', isChecked: this.activity.thursday },
        { val: 'Friday', isChecked: this.activity.friday },
        { val: 'Saturday', isChecked: this.activity.saturday },
        { val: 'Sunday', isChecked: this.activity.sunday },
      ] : [];
    }
  },
  computed:{
  },
  data() {
    return {
      isRepeat: this.checkRepeats(),
      repeat : this.fillRepeat()
    }
  },
  validations(){
    return{
      activity:{
        name: {
          required
        },
        description:{
          required
        },
        // type:'',
        created:{
          required
        },
        startTime:{
          required
        },
        distance: {
          required
        },
        locationInstruction: {

        },
        address:{

        },
        group:{
          id:{ required}
        },
        // privateActivity: false,
        monday:{},
        tuesday:{},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
      },
    }
  },
}

