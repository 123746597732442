

import {
  IonInput, IonItem, IonTextarea, IonLabel,IonToggle,
  IonSelect, IonSelectOption, IonButton,  alertController
} from "@ionic/vue";

function initialState(){
  return{
      name: '',
      description:'',
      privateGroup: false,
      city:'',
      state:'FL',
      type:'MULTISPORT',
  }
}

export default {
  name: 'groupForm',
  props:{
    groupInfo:{
      type:Object,
      default: initialState(),
    },
    action:{
      type:String,
      default: 'submit'
    }
  },
  emits: ['groupSubmit'],
  components: {
    IonInput,
    IonItem,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonToggle,
    IonLabel
  },
  methods: {
    submit(){
      this.$emit("groupSubmit", this.group);
    },
    changePrivacy() {
      this.group.privateGroup = !this.group.privateGroup;
    },
    reset(){
      Object.assign(this.group, initialState());
    },
    async presentAlert(error) {
      const alert = await alertController.create({
        header: 'Error',
        message: error,
        buttons: ['OK']
      });
      await alert.present();
    },
  },
  data(){
    return {
      // group: this.groupInfo,
      group:{
        id: this.groupInfo.id,
        name: this.groupInfo.name,
        description:this.groupInfo.description,
        privateGroup: this.groupInfo.privateGroup,
        city:this.groupInfo.city,
        state: this.groupInfo.state,
        type: this.groupInfo.type,
      }
    }
  }
}

