

import { IonContent, IonHeader, IonToolbar,  IonTitle, IonButton, IonButtons, IonModal,
  IonIcon,useIonRouter} from '@ionic/vue';
import {chevronBackOutline, saveOutline, trashOutline} from "ionicons/icons";
import RoleChangeModal from "@/components/modal/RoleChangeModal.vue";
import MemberItem from "@/components/item/MemberItem.vue";

export default {
  name: 'AdminModal',
  props: {
    group: Object,
  },
  emits: ['modalSave', 'modalCancel'],
  components: {
    MemberItem,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
    IonModal,
    RoleChangeModal
  },
  setup() {
    const ionRouter = useIonRouter();
    return {
      // v: useVuelidate(),
      saveOutline,
      trashOutline,
      chevronBackOutline,
      ionRouter
    }
  },
  methods: {
    save(group) {
      // this.$store.dispatch('group/update', group).then(
      //     () => {
      //       this.$emit("modalSave");
      //     }
      // );
    },
    openRespond(id){
      this.user = id;
      this.responseOpen = !this.responseOpen;
    },
    closeRespond(){
      this.responseOpen = false;
    },
    cancel() {
      this.$emit("modalCancel");
    },
  },
  computed: {},
  data() {
    return {
      responseOpen: false,
    }
  }
}
